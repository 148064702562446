export const ContractName = {
  PITContract: "PITContract",
  PITLeverageContract: "PITLeverageContract",
  PriceContract: "PriceContract",
  UniswapV2FactoryContract: "UniswapV2FactoryContract",
  ERC20Token: "ERC20Token",
  LPToken: "LPToken",
  ERC4626Token: "ERC4626Token",
  MultiCallContract: "MultiCallContract",
  FTokenContract: "FTokenContract",
  JumpRateModelContract: "JumpRateModelContract",
};
