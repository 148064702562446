import { ZERO_ADDRESS } from "utils/network";
import _get from "lodash/get";

export function handleGetAllowanceForFToken(allowanceList) {
  const result = {};
  allowanceList.forEach((o) => {
    const key = _get(o, ["reference"], ZERO_ADDRESS);
    const value = _get(o, ["returnValues", 0], 0);

    result[key] = {
      allowance: value,
    };
  });

  return result;
}
