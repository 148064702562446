export const PITContractMethod = {
  lendingTokenInfo: "lendingTokenInfo",
  borrowLimitPerCollateral: "borrowLimitPerCollateral",
  borrowLimitPerLendingToken: "borrowLimitPerLendingToken",
  pitRemaining: "pitRemaining",
  pitCollateral: "pitCollateral",
  getPosition: "getPosition",
  isLeveragePosition: "isLeveragePosition",
  getLeverageType: "getLeverageType",
  projectTokenInfo: "projectTokenInfo",
  pitRemainingWithUpdatePrices: "pitRemainingWithUpdatePrices",
  getTokenEvaluation: "getTokenEvaluation",
  getTokenEvaluationWithUpdatePrices: "getTokenEvaluationWithUpdatePrices",
  getLendingToken: "getLendingToken",
  getLendingAvailableToBorrow: "getLendingAvailableToBorrow",
  getLendingAvailableToBorrowWithUpdatePrices: "getLendingAvailableToBorrowWithUpdatePrices",
  getTotalBorrowPerLendingToken: "getTotalBorrowPerLendingToken",
  getCollateralAvailableToWithdrawWithUpdatePrices:
    "getCollateralAvailableToWithdrawWithUpdatePrices",
};

export const PriceContractMethod = {
  getUpdatedPriceMethod: "getUpdatedPrice",
  updatePricesMethod: "updatePrices",
};

export const ERC20TokenMethod = {
  balanceOf: "balanceOf",
  name: "name",
  allowance: "allowance",
  decimals: "decimals",
  symbol: "symbol",
};

export const FTokenMethod = {
  ...ERC20TokenMethod,
  supplyRatePerBlock: "supplyRatePerBlock",
  borrowRatePerBlock: "borrowRatePerBlock",
  accountTokens: "accountTokens",
  balanceOfUnderlyingView: "balanceOfUnderlyingView",
  exchangeRateStored: "exchangeRateStored",
  totalBorrows: "totalBorrows",
  getCash: "getCash",
};

export const LPTokenMethod = {
  ...ERC20TokenMethod,
  token0: "token0",
  token1: "token1",
};

export const ERC4626TokenMethod = {
  ...ERC20TokenMethod,
  asset: "asset",
};

export const UniswapV2FactoryMethod = {
  getPair: "getPair",
};

export const MulticallMethod = {
  getEthBalance: "getEthBalance",
};

export const JumpRateModelMethod = {
  blocksPerYear: "blocksPerYear",
};
