export const TRANSACTION_TIMEOUT_MS = 10000; // 10s

export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID_ETHEREUM;

const message = {
  1: "Please choose the Ethereum Main Network",
  10: "Please choose the Optimism Main Network",
  137: "Please choose the Polygon Main Network",
  42161: "Please choose the Arbitrum Main Network",
  5: "Please choose the Ethereum (Goerli Test) Network",
  80001: "Please choose the Polygon (Mumbai Test) Network",
  420: "Please choose the Optimism (Goerli Test) Network",
  421613: "Please choose the Arbitrum (Goerli Test) Network",
};

export const CHAIN_ERROR_MESSAGE = message[+CHAIN_ID || 5];

export const getMessageWrongNetwork = (chainId = +CHAIN_ID || 5) => message[chainId];

export const DECIMAL_SCALE = 6;
export const DECIMAL_ETHER = 18;

export const CHART_COLORS = {
  total_pit: "#14A38B",
  total_amount_collateral_deposited: "#FF7171",
  total_outstanding: "#F2AC57",
  collateral_vs_loan_ration: "#A5A6F6",
  apy: "#EF5DA8",
  lender_apy: "#78FF56",
  total_supply: "#FFF494",
};

export const EVENT_TYPES = {
  deposit: "Deposit",
  borrow: "Borrowed",
  withdraw: "Withdraw",
  repay: "Repay",
};

export const NATIVE_TOKEN = {
  [process.env.REACT_APP_CHAIN_ID_ETHEREUM]: {
    logo: "https://s2.coinmarketcap.com/static/img/coins/200x200/1027.png",
    name: "Ether",
    symbol: "ETH",
  },
  [process.env.REACT_APP_CHAIN_ID_POLYGON]: {
    logo: "https://s2.coinmarketcap.com/static/img/coins/200x200/3890.png",
    name: "POL",
    symbol: "POL",
  },
  [process.env.REACT_APP_CHAIN_ID_ARBITRUM]: {
    logo: "https://s2.coinmarketcap.com/static/img/coins/200x200/1027.png",
    name: "Ether",
    symbol: "ETH",
  },
  [process.env.REACT_APP_CHAIN_ID_OPTIMISM]: {
    logo: "https://s2.coinmarketcap.com/static/img/coins/200x200/1027.png",
    name: "Ether",
    symbol: "ETH",
  },
  [process.env.REACT_APP_CHAIN_ID_ZKSYNC]: {
    logo: "https://s2.coinmarketcap.com/static/img/coins/200x200/1027.png",
    name: "Ether",
    symbol: "ETH",
  },
  [process.env.REACT_APP_CHAIN_ID_BASE]: {
    logo: "https://s2.coinmarketcap.com/static/img/coins/200x200/1027.png",
    name: "Ether",
    symbol: "ETH",
  },
};

export const REPAY_MAX_BUFFER = 0.05;
export const BORROW_MAX_BUFFER = 0.05;
export const MARGIN_BUFFER = 0.0005;
export const REPAY_TOLERANCE_AMOUNT = 1e-8;
