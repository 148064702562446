import { gql, useQuery as useClientQuery } from "@apollo/client";
import useWallet from "hooks/useWallet";
import { ProjectTokenContext } from "./ProjectTokenContext";

const GET_PRJ_TOKEN = gql`
  query ExampleQuery($account: String!) {
    projectTokens {
      name
      symbol
      address
      decimals
      isDepositPaused
      isWithdrawPaused
      underlyingTokens {
        id
        name
        symbol
        address
        linksNumber
      }
      depositedAmount
      lvr
      pitAmount
      borrowedAmount
      outstandingAmount
      borrowingLevelAmount
      currentBorrowingLevel
      __typename
    }

    totalStates {
      type
      amount
      lendingTokenAddress
    }

    lendingTokens {
      name
      symbol
      decimals
      address
      fTokenAddress
      isPaused
      underlyingTokens {
        id
        name
        symbol
        address
        linksNumber
      }
    }
    borrowLogs(where: { userAddress: $account }, orderBy: date, orderDirection: desc) {
      id
      amount
      asset
      prjToken
      type
      date
      userAddress
      prjTokenAddress
    }
  }
`;

export const ProjectTokenProvider = ({ children }) => {
  const { account: address } = useWallet();

  const resp = useClientQuery(GET_PRJ_TOKEN, {
    variables: { account: address },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first",
  });

  return (
    <ProjectTokenContext.Provider
      value={{
        ...resp,
        loading: resp.loading,
      }}
    >
      {children}
    </ProjectTokenContext.Provider>
  );
};
