export const CHAINID_NETWORK = {
  arbitrum: process.env.REACT_APP_CHAIN_ID_ARBITRUM,
  optimism: process.env.REACT_APP_CHAIN_ID_OPTIMISM,
  polygon: process.env.REACT_APP_CHAIN_ID_POLYGON,
  ethereum: process.env.REACT_APP_CHAIN_ID_ETHEREUM,
  zkSync: process.env.REACT_APP_CHAIN_ID_ZKSYNC,
  base: process.env.REACT_APP_CHAIN_ID_BASE,
};

export const CHAINID_WITH_API = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_API_URL_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_API_URL_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_API_URL_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_API_URL_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_API_URL_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_API_URL_BASE,
};

// Address of contract hooks

export const PRIMARY_INDEX_TOKEN = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_SC_PRIMARY_INDEX_TOKEN_BASE,
};

export const BUSDC_CONTRACT = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_SC_BUSDC_ADDRESS,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_SC_BUSDC_ADDRESS_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_SC_BUSDC_ADDRESS_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_SC_BUSDC_ADDRESS_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_SC_BUSDC_ADDRESS_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_SC_BUSDC_ADDRESS_BASE,
};

export const PRICE_PROVIDER_AGGREGATOR_CONTRACT = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_PRICE_PROVIDER_AGGREGATOR_BASE,
};

export const LENDING_TOKEN_ADDRESS = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_BASE,
};

export const LENDING_USDC_TOKEN_ADDRESS = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_LENDING_TOKEN_ADDRESS_BASE,
};

export const ATOMIC_REPAYMENT_CONTRACT = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_LENDING_TOKEN_ATOMIC_REPAYMENT_ADDRESS_BASE,
};

export const REACT_APP_UNISWAPV2_FACTORY_ETHEREUM = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_UNISWAPV2_FACTORY_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_UNISWAPV2_FACTORY_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_UNISWAPV2_FACTORY_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_UNISWAPV2_FACTORY_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_UNISWAPV2_FACTORY_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_UNISWAPV2_FACTORY_BASE,
};

export const REACT_APP_UNISWAPV2_ROUTER = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_UNISWAPV2_ROUTER_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_UNISWAPV2_ROUTER_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_UNISWAPV2_ROUTER_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_UNISWAPV2_ROUTER_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_UNISWAPV2_ROUTER_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_UNISWAPV2_ROUTER_BASE,
};

export const REACT_APP_INSTANT_LEVERAGE = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_INSTANT_LEVERAGE_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_INSTANT_LEVERAGE_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_INSTANT_LEVERAGE_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_INSTANT_LEVERAGE_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_INSTANT_LEVERAGE_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_INSTANT_LEVERAGE_BASE,
};

export const REACT_APP_MULTICALL = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_MULTICALL_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_MULTICALL_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_MULTICALL_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_MULTICALL_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_MULTICALL_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_MULTICALL_BASE,
};

export const REACT_APP_ACCOUNT_HAVING_ETH = {
  [CHAINID_NETWORK.ethereum]: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", // WETH
  [CHAINID_NETWORK.polygon]:
    process.env.REACT_APP_NETWORK === "testnet"
      ? "0x9c3c9283d3e44854697cd22d3faa240cfb032889"
      : "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270", // MATIC
  [CHAINID_NETWORK.arbitrum]:
    process.env.REACT_APP_NETWORK === "testnet"
      ? "0x980B62Da83eFf3D4576C647993b0c1D7faf17c73"
      : "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1", // WETH
  [CHAINID_NETWORK.optimism]: "0x4200000000000000000000000000000000000006", // WETH,
  [CHAINID_NETWORK.zkSync]:
    process.env.REACT_APP_NETWORK === "testnet"
      ? "0x20b28B1e4665FFf290650586ad76E977EAb90c5D"
      : "0x8Ebe4A94740515945ad826238Fc4D56c6B8b0e60", // WETH
  [CHAINID_NETWORK.base]:
    process.env.REACT_APP_NETWORK === "testnet"
      ? "0x20b28B1e4665FFf290650586ad76E977EAb90c5D"
      : "0x8Ebe4A94740515945ad826238Fc4D56c6B8b0e60",
};

export const LENDING_TOKEN_LIST = [
  {
    ftoken: "0x26868261FB06113cd347A33799Bf8E643c56734f",
    token: "0xccc70874548F12320DB34b1f36343C5afc5484e4",
    logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
    name: "USDC",
  },
  {
    ftoken: "0xcEC6943aC4462FBFCc9AD0Be6B1391e2B3b17422",
    token: "0xFee9CFE582C501234C8ac18b01EA3F3199f4efB8",
    logo: "/assets/images/usb_token.png",
    name: "USB",
  },
];

export const JUMP_RATE_MODEL = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_JUMP_RATE_MODEL_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_JUMP_RATE_MODEL_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_JUMP_RATE_MODEL_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_JUMP_RATE_MODEL_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_JUMP_RATE_MODEL_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_JUMP_RATE_MODEL_BASE,
};

export const REACT_APP_MUTE_FACTORY = {
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_MUTE_FACTORY_ZKSYNC,
};

export const REACT_APP_MUTE_ROUTER = {
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_MUTE_ROUTER_ZKSYNC,
};

export const REACT_APP_PYTH_PRICE_PROVIDER = {
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_PYTH_PRICE_PROVIDER_POLYGON,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_PYTH_PRICE_PROVIDER_ARBITRUM,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_PYTH_PRICE_PROVIDER_ETHEREUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_PYTH_PRICE_PROVIDER_OPTIMISM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_PYTH_PRICE_PROVIDER_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_PYTH_PRICE_PROVIDER_BASE,
};

function decodeSpecialTokenSymbol(config = "") {
  if (!config || config.length === 0) return {};
  const element = config.split(",");
  if (element.length < 2) return {};
  const specialSymbols = {};
  for (let i = 0; i < element.length; i += 2) {
    const address = element[i].toLowerCase();
    const symbol = element[i + 1];
    specialSymbols[address] = symbol;
  }
  return specialSymbols;
}

export const REACT_APP_SPECIAL_TOKEN_SYMBOLS = {
  [CHAINID_NETWORK.arbitrum]: decodeSpecialTokenSymbol(
    process.env.REACT_APP_SPECIAL_TOKEN_SYMBOLS_ARBITRUM
  ),
  [CHAINID_NETWORK.optimism]: decodeSpecialTokenSymbol(
    process.env.REACT_APP_SPECIAL_TOKEN_SYMBOLS_OPTIMISM
  ),
  [CHAINID_NETWORK.polygon]: decodeSpecialTokenSymbol(
    process.env.REACT_APP_SPECIAL_TOKEN_SYMBOLS_POLYGON
  ),
  [CHAINID_NETWORK.ethereum]: decodeSpecialTokenSymbol(
    process.env.REACT_APP_SPECIAL_TOKEN_SYMBOLS_ETHEREUM
  ),
  [CHAINID_NETWORK.zkSync]: decodeSpecialTokenSymbol(
    process.env.REACT_APP_SPECIAL_TOKEN_SYMBOLS_ZKSYNC
  ),
  [CHAINID_NETWORK.zkSync]: decodeSpecialTokenSymbol(
    process.env.REACT_APP_SPECIAL_TOKEN_SYMBOLS_BASE
  ),
};

export const REACT_APP_WRAP_NATIVE_TOKEN = {
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_WRAP_NATIVE_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_WRAP_NATIVE_OPTIMISM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_WRAP_NATIVE_POLYGON,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_WRAP_NATIVE_ETHEREUM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_WRAP_NATIVE_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_WRAP_NATIVE_BASE,
};

export const PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY = {
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY_ARBITRUM,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY_OPTIMISM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY_POLYGON,
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY_ETHEREUM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_PRIMARY_LENDING_WRAPPED_TOKEN_GATEWAY_BASE,
};
