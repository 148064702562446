import {
  AppBar,
  Button,
  Container,
  Hidden,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";

import { ReactComponent as EthereumIcon } from "assets/ethereum.svg";
import { ReactComponent as PolygonIcon } from "assets/polygon.svg";
import { ReactComponent as ArbitrumIcon } from "assets/svg/arbitrum.svg";
import { ReactComponent as OptimismIcon } from "assets/svg/optimism.svg";
import { ReactComponent as ZkSyncIcon } from "assets/zksync.svg";
import { ReactComponent as BaseIcon } from "assets/base.svg";
import Dialog from "components/Dialog/Dialog";
import PlatformSelect from "components/Header/components/PlatformSelect/PlatformSelect";
import { CHAINID_NETWORK } from "constants/NetworkChainId";
import { useWallet } from "hooks";
import { NavLink } from "react-router-dom";
import truncateText from "utils/utils";
import { useDisconnect } from "wagmi";
import { ConnectWalletDialog } from "./components";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import SwitchNetworkDialog from "./components/SwitchNetworkDialog/SwitchNetworkDialog";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
  },
  appBar: {
    padding: "16px 24px",
    border: "none",
  },

  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },

  navigation: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    marginLeft: 40,
  },

  link: {
    paddingBottom: 3,
    borderBottom: "2px solid transparent",

    lineHeight: "20px",
    fontSize: 16,
    color: theme.palette.common.white,

    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(2),
    },

    "&:hover, &.active": {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      boxSizing: "border-box",
    },
  },

  button: {
    width: "100%",
    maxWidth: "400px",
    marginTop: 30,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      marginTop: 0,
    },
  },

  disabledButton: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },

  ethereumIcon: {
    width: "2rem",
    height: "2rem",
  },

  polygonIcon: {
    width: 30,
    height: 30,
  },

  logoLink: {
    display: "flex",
    alignItems: "center",
  },
  wallet: {
    color: "#515151",
    fontSize: 15,
    lineHeight: "19px",
    fontWeight: 500,
  },
  walletAddress: {
    color: theme.palette.success.light,
    fontWeight: 500,
  },
  switchNetwork: {
    padding: 5,
    marginTop: 10,
    marginLeft: 10,
    marginRight: "auto",
    background: "rgba(243, 132, 30, 0.05)",
    [theme.breakpoints.up("lg")]: {
      width: "auto",
      marginTop: 0,
    },
    "&:hover": {
      background: "rgba(243, 132, 30, 0.05)",
    },
  },
  networkText: {
    fontSize: "1.5rem",
    margin: "auto",
    marginLeft: 8,
  },
  chainId: {
    color: "#fff",
    padding: 0,
  },
  buttonWrapper: {
    display: "flex",
    padding: "0 4px",
  },
  selectNetwork: {
    color: "red",
    fontSize: 14,
  },
}));

const LINKS = [
  {
    path: "/",
    label: "Borrow",
    exact: true,
  },
  {
    path: "/lend",
    label: "Lend",
    exact: false,
  },
  {
    path: "/amplify",
    label: "Amplify",
    exact: false,
  },
  {
    path: "/margin-trade",
    label: "Margin Trade",
    exact: false,
  },
];

const Header = () => {
  const {
    account,
    chainId,
    connected,
    isWrongChain,
    onSwitchChain,
    onCloseSwitchChain,
    isConnecting,
    onConnecting,
    onCloseConnect,
    isNetworkUnsupported,
  } = useWallet();

  const isIncludeLeveragePage = process.env.REACT_APP_INCLUDE_LEVERAGE === "true";

  const links = LINKS.filter((s) =>
    s.path === "/amplify" || s.path === "/margin-trade" ? isIncludeLeveragePage : true
  );

  const classes = useStyles();

  const { disconnect } = useDisconnect();

  return (
    <div className={classes.header}>
      <Container>
        <AppBar position="static" color="transparent" elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <PlatformSelect />
            <Hidden mdDown>
              <nav className={classes.navigation}>
                {links.map(({ path, label, exact }) => (
                  <Link
                    key={path}
                    to={path}
                    exact={exact}
                    component={NavLink}
                    className={classes.link}
                    underline="none"
                  >
                    {label}
                  </Link>
                ))}
              </nav>
            </Hidden>

            <Hidden lgUp>
              <MobileMenu links={links} />
            </Hidden>

            {connected ? (
              <>
                <Typography className={classes.wallet}>
                  Connected wallet:{" "}
                  <Typography className={classes.walletAddress} component="span">
                    {truncateText(account)}
                  </Typography>
                </Typography>
                <IconButton
                  onClick={disconnect}
                  style={{ marginLeft: "8px", marginRight: "8px", color: "white" }}
                >
                  <PowerSettingsNewOutlinedIcon />
                </IconButton>
                <Button onClick={onSwitchChain} className={classes.switchNetwork}>
                  <Typography className={classes.chainId} component="span">
                    {isNetworkUnsupported ? (
                      <Typography className={classes.selectNetwork}>Wrong Network</Typography>
                    ) : (
                      <>
                        {+chainId === +CHAINID_NETWORK.polygon && (
                          <div className={classes.buttonWrapper}>
                            <PolygonIcon className={classes.polygonIcon} />
                            <Typography className={classes.networkText}>Polygon</Typography>
                          </div>
                        )}
                        {+chainId === +CHAINID_NETWORK.optimism && (
                          <div className={classes.buttonWrapper}>
                            <OptimismIcon className={classes.polygonIcon} />
                            <Typography className={classes.networkText}>Optimism</Typography>
                          </div>
                        )}
                        {+chainId === +CHAINID_NETWORK.arbitrum && (
                          <div className={classes.buttonWrapper}>
                            <ArbitrumIcon className={classes.polygonIcon} />
                            <Typography className={classes.networkText}>Arbitrum</Typography>
                          </div>
                        )}
                        {+chainId === +CHAINID_NETWORK.ethereum && (
                          <div className={classes.buttonWrapper}>
                            <EthereumIcon className={classes.ethereumIcon} />{" "}
                            <Typography className={classes.networkText}>Ethereum</Typography>
                          </div>
                        )}
                        {+chainId === +CHAINID_NETWORK.zkSync && (
                          <div className={classes.buttonWrapper}>
                            <ZkSyncIcon className={classes.ethereumIcon} />
                            <Typography className={classes.networkText}>zkSync</Typography>
                          </div>
                        )}
                        {+chainId === +CHAINID_NETWORK.base && (
                          <div className={classes.buttonWrapper}>
                            <BaseIcon className={classes.ethereumIcon} />
                            <Typography className={classes.networkText}>Base</Typography>
                          </div>
                        )}
                      </>
                    )}
                  </Typography>
                </Button>
              </>
            ) : (
              <Button onClick={onConnecting} className={classes.button}>
                Connect Wallet
              </Button>
            )}
          </Toolbar>

          <Dialog open={isConnecting && !connected} onClose={onCloseConnect}>
            <ConnectWalletDialog onClose={onCloseConnect} />
          </Dialog>
          <Dialog open={isWrongChain} onClose={onCloseSwitchChain}>
            <SwitchNetworkDialog onClose={onCloseSwitchChain} />
          </Dialog>
        </AppBar>
      </Container>
    </div>
  );
};

export default Header;
