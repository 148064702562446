import { DECIMAL_ETHER } from "app/constants";
import { BigNumber, constants } from "ethers";
import _get from "lodash/get";
import _reduce from "lodash/reduce";

const getApy = (rate, blockPerYear) => {
  // eslint-disable-next-line no-restricted-properties
  const rateIntMath = Math.pow(+rate / 10 ** DECIMAL_ETHER + 1, blockPerYear) - 1;

  return rateIntMath.toString();
};

export function handleApy(results, blocksPerYear) {
  const fTokenApyList = _reduce(
    results,
    (result, value, key) => {
      const borrowRate = _get(value, [1, "returnValues", 0], BigNumber.from(0));

      const lenderRate = _get(value, [0, "returnValues", 0], BigNumber.from(0));

      const token = _get(value, [0, "reference"], constants.AddressZero);

      const data = {
        lenderApy: getApy(+borrowRate, +blocksPerYear),
        borrowApy: getApy(+lenderRate, +blocksPerYear),
        borrowRate: +borrowRate,
        lenderRate: +lenderRate,
        ftoken: key,
      };
      return { ...result, [token]: data };
    },
    {}
  );

  return fTokenApyList;
}
