/* eslint-disable no-await-in-loop */
import { parseUnits } from "utils/number";
import { MainNetworkSupported } from "utils/network";
import { generateMulticallRequest } from "utils/contract/multicall";
import {
  ERC20TokenMethod,
  FTokenMethod,
  JumpRateModelMethod,
  PITContractMethod,
  UniswapV2FactoryMethod,
} from "constants/methodName";
import { ContractName } from "constants/contractName";
import { Contract } from "ethers";
import { ERC20TokenABI, FTokenABI } from "utils/ethereum/abi";

// fetch batch with contraints group
export function prepareRequestMulticallZksync({
  PITContract,
  JumpRateModelContract,
  listToken: collateralTokens,
  availableBorrowTokens: lendingTokenList,
  account,
  decimalTokens,
  chainId,
  UniswapV2FactoryContract,
}) {
  const usdcToken = lendingTokenList.find((o) => o.symbol === "USDC" || o.symbol === "USDC.e");
  const usdcAddress = usdcToken?.address || "";

  const listRequestToPITContract = [];
  const listRequestToUniswapV2FactoryContract = [];
  const listRequestToFTokenContract = [];
  const listRequestToTokenContract = [];

  collateralTokens.forEach((prjToken) => {
    listRequestToPITContract.push(
      generateMulticallRequest(
        PITContract,
        PITContractMethod.getTokenEvaluation,
        [prjToken.address, parseUnits("1.0", decimalTokens[prjToken.address])],
        prjToken.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.borrowLimitPerCollateral,
        [prjToken.address],
        prjToken.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.pitCollateral,
        [account, prjToken.address],
        prjToken.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.getLendingToken,
        [account, prjToken.address],
        prjToken.symbol,
        ContractName.PITContract
      )
    );

    lendingTokenList.forEach((lendingToken) => {
      listRequestToPITContract.push(
        generateMulticallRequest(
          PITContract,
          PITContractMethod.getLendingAvailableToBorrow,
          [account, prjToken.address, lendingToken.address],
          lendingToken.symbol,
          ContractName.PITContract
        ),
        generateMulticallRequest(
          PITContract,
          PITContractMethod.getPosition,
          [account, prjToken.address, lendingToken.address],
          lendingToken.symbol,
          ContractName.PITContract
        ),
        generateMulticallRequest(
          PITContract,
          PITContractMethod.pitRemaining,
          [account, prjToken.address, lendingToken.address],
          lendingToken.symbol,
          ContractName.PITContract
        )
      );
    });
  });

  lendingTokenList.forEach((lending) => {
    const tokenContract = new Contract(lending.address, ERC20TokenABI);
    const fTokenContract = new Contract(lending.fTokenAddress, FTokenABI);
    listRequestToPITContract.push(
      generateMulticallRequest(
        PITContract,
        PITContractMethod.getTokenEvaluation,
        [lending.address, parseUnits("1.0", decimalTokens[lending.address])],
        lending.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.borrowLimitPerLendingToken,
        [lending.address],
        lending.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.lendingTokenInfo,
        [lending.address],
        lending.address,
        ContractName.PITContract
      ),
      generateMulticallRequest(
        PITContract,
        PITContractMethod.getTotalBorrowPerLendingToken,
        [lending.address],
        lending.address,
        ContractName.PITContract
      )
    );

    listRequestToFTokenContract.push(
      generateMulticallRequest(
        fTokenContract,
        FTokenMethod.borrowRatePerBlock,
        [],
        lending.address,
        fTokenContract.address
      ),
      generateMulticallRequest(
        fTokenContract,
        FTokenMethod.supplyRatePerBlock,
        [],
        lending.address,
        fTokenContract.address
      ),
      generateMulticallRequest(
        fTokenContract,
        FTokenMethod.getCash,
        [],
        lending.address,
        fTokenContract.address
      )
    );

    listRequestToTokenContract.push(
      generateMulticallRequest(
        tokenContract,
        ERC20TokenMethod.allowance,
        [account, lending.fTokenAddress],
        lending.address,
        ContractName.ERC20Token
      )
    );

    if (!MainNetworkSupported.includes(+chainId)) {
      listRequestToUniswapV2FactoryContract.push([
        generateMulticallRequest(
          UniswapV2FactoryContract,
          UniswapV2FactoryMethod.getPair,
          [lending.address, usdcAddress],
          usdcAddress,
          ContractName.UniswapV2FactoryContract
        ),
      ]);
    }
  });

  return [
    generateMulticallRequest(
      JumpRateModelContract,
      JumpRateModelMethod.blocksPerYear,
      [],
      JumpRateModelContract.address,
      ContractName.JumpRateModelContract
    ),
    ...listRequestToPITContract,
    ...listRequestToFTokenContract,
    ...listRequestToTokenContract,
    ...listRequestToUniswapV2FactoryContract,
  ];
}
