import { usePriceContract } from "hooks/contract/core/usePriceContract";
import { useQuery } from "react-query";
import { getDataToUpdatePrice } from "utils/ethereum/getDataToUpdatePrice";

export const useDataToUpdatePrice = (tokenAddresses = []) => {
  const { PriceContract } = usePriceContract();
  const keys = tokenAddresses.map((address) => address.toLowerCase()).sort();
  const query = useQuery(
    ["get-pyth-data-to-update-price", keys],
    async () => {
      const res = await getDataToUpdatePrice(tokenAddresses, PriceContract);

      return res;
    },
    {
      enabled: !!(PriceContract && tokenAddresses?.length),
      retry: 3,
      cacheTime: 15000,
    }
  );

  return query;
};
