import { constants, Contract } from "ethers";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import ReactGa from "react-ga";
import { useMutation, useQueryClient } from "react-query";

import { useEventContractAction } from "context/EventContract/EventContractProvider";
import { useWallet } from "hooks";
import { usePITContractWrite } from "hooks/contract/usePITContract";
import { ERC20TokenABI } from "utils/ethereum/abi";
import { PIT_EVENT } from "utils/ethereum/pitEventConstant";
import { usePITWrappedTokenGatewayContract } from "hooks/contract/usePITWrappedTokenGatewayContract";
import { isWrapNative } from "utils/token";

const useLendingTokenMutations = () => {
  const queryClient = useQueryClient();
  const { onEvent } = useEventContractAction();
  const { enqueueSnackbar } = useSnackbar();
  const { account, chainId, signer } = useWallet();
  const {
    callback: {
      supplyCall: supplyERC20Call,
      redeemUnderlyingCall: redeemUnderlyingERC20Call,
      borrowCall: borrowERC20Call,
      repayCall: repayERC20Call,
    },
  } = usePITContractWrite();

  const {
    callback: {
      supplyCall: supplyETHCall,
      redeemUnderlyingCall: redeemUnderlyingETHCall,
      borrowCall: borrowETHCall,
      repayCall: repayETHCall,
    },
  } = usePITWrappedTokenGatewayContract();

  const handleError = useCallback(
    (error) => {
      onEvent(null);
      if (error.message === "userRejectedRequest") {
        enqueueSnackbar("Transaction has been canceled", {
          variant: "warning",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(`Transaction rejected: ${error.message}`.split(/[([]/)[0], {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [enqueueSnackbar, onEvent]
  );

  const approve = useMutation(
    async ({ address, ftoken, value }) => {
      const contract = new Contract(address, ERC20TokenABI, signer);

      const approved = await contract.approve(ftoken, value || constants.MaxUint256);
      await approved.wait();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["#User-F-Token-Balance-lending", account, chainId]);
        queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        enqueueSnackbar("Transaction approved!", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      onError: handleError,
    }
  );

  const supply = useMutation(
    ({ lendingTokenAmount, address }) =>
      isWrapNative(address, chainId)
        ? supplyETHCall({ lendingTokenAmount })
        : supplyERC20Call({ lendingTokenAddress: address, lendingTokenAmount }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["#User-F-Token-Balance-lending", account, chainId]);
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      onError: handleError,
    }
  );

  const redeemUnderlying = useMutation(
    ({ lendingTokenAmount, address }) =>
      isWrapNative(address, chainId)
        ? redeemUnderlyingETHCall({
            lendingTokenAmount,
          })
        : redeemUnderlyingERC20Call({
            lendingTokenAddress: address,
            lendingTokenAmount,
          }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["#User-F-Token-Balance-lending", account, chainId]);
        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });
      },
      onError: handleError,
    }
  );

  const borrow = useMutation(
    ({ prjAddress, prjAmount, lendingToken }) =>
      isWrapNative(lendingToken, chainId)
        ? borrowETHCall({
            collateral: prjAddress,
            lendingAmount: prjAmount,
          })
        : borrowERC20Call({
            collateral: prjAddress,
            lendingToken,
            lendingAmount: prjAmount,
          }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["available-multicall", account]);
        queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        onEvent(PIT_EVENT.Borrow);

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Borrow success",
        });
      },
      onError: handleError,
    }
  );

  const repay = useMutation(
    ({ borrowToken, prjAddress, prjAmount, prjAmountPayable }) =>
      isWrapNative(borrowToken, chainId)
        ? repayETHCall({ collateral: prjAddress, lendingAmount: prjAmountPayable })
        : repayERC20Call({
            lending: borrowToken,
            collateral: prjAddress,
            collateralAmount: prjAmount,
          }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["available-multicall", account]);
        queryClient.invalidateQueries(["borrowed-pit-multicall", account]);
        onEvent(PIT_EVENT.RepayBorrow);

        enqueueSnackbar("The transaction has been confirmed successfully!", {
          variant: "success",
          autoHideDuration: 5000,
        });

        ReactGa.event({
          category: "Borrower Dashboard",
          action: "Repay success",
        });
      },
      onError: handleError,
    }
  );

  return {
    isLoading:
      approve.isLoading ||
      supply.isLoading ||
      redeemUnderlying.isLoading ||
      borrow.isLoading ||
      repay.isLoading,
    approve: approve.mutateAsync,
    supply: supply.mutateAsync,
    redeemUnderlying: redeemUnderlying.mutateAsync,
    borrow: borrow.mutateAsync,
    repay: repay.mutateAsync,
  };
};

export default useLendingTokenMutations;
