export const PolygonNetwork = {
  chainId: process.env.REACT_APP_CHAIN_ID_POLYGON,
  rpcUrls: process.env.REACT_APP_RPC_POLYGON,
  name: process.env.REACT_APP_NAME_POLYGON,
  blockUrl: process.env.REACT_APP_URL_POLYGON,
};

export const OptimismNetwork = {
  chainId: process.env.REACT_APP_CHAIN_ID_OPTIMISM,
  rpcUrls: process.env.REACT_APP_RPC_OPTIMISM,
  name: process.env.REACT_APP_NAME_OPTIMISM,
  blockUrl: process.env.REACT_APP_URL_OPTIMISM,
};

export const ArbitrumNetwork = {
  chainId: process.env.REACT_APP_CHAIN_ID_ARBITRUM,
  rpcUrls: process.env.REACT_APP_RPC_ARBITRUM,
  name: process.env.REACT_APP_NAME_ARBITRUM,
  blockUrl: process.env.REACT_APP_URL_ARBITRUM,
};

export const EthereumNetwork = {
  chainId: process.env.REACT_APP_CHAIN_ID_ETHEREUM,
  rpcUrls: process.env.REACT_APP_RPC_ETHEREUM,
  name: process.env.REACT_APP_NAME_ETHEREUM,
  blockUrl: process.env.REACT_APP_URL_ETHEREUM,
};

export const ZkSynceNetwork = {
  chainId: process.env.REACT_APP_CHAIN_ID_ZKSYNC,
  rpcUrls: process.env.REACT_APP_RPC_ZKSYNC,
  name: process.env.REACT_APP_NAME_ZKSYNC,
  blockUrl: process.env.REACT_APP_URL_ZKSYNC,
};

export const BaseNetwork = {
  chainId: process.env.REACT_APP_CHAIN_ID_BASE,
  rpcUrls: process.env.REACT_APP_RPC_BASE,
  name: process.env.REACT_APP_NAME_BASE,
  blockUrl: process.env.REACT_APP_URL_BASE,
};
