import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from "@material-ui/core";
import { constants } from "ethers";
import _get from "lodash/get";
import { useSnackbar } from "notistack";
import { useDebugValue, useEffect, useMemo, useRef, useState } from "react";
import { formatUnits, minBigNumber, numberToString, parseUnits } from "utils/number";
import { getPrimaryIndexTokenAtomicRepaymentZksync } from "utils/ethereum/contracts";
import { DECIMAL_SCALE, EVENT_TYPES, REPAY_TOLERANCE_AMOUNT } from "app/constants";
import {
  DialogApplyButton,
  DialogLogo,
  HealthFactorProgressBar,
  NumericText,
  NumericTextField,
  Spinner,
} from "components";

import BigNumber from "bignumber.js";
import { useBorrowContext } from "context/contracts/BorrowContext";
import * as ethers from "ethers";
import { useWallet } from "hooks";
import { useAtomicRepayment } from "hooks/atomic/useAtomicRepayment";
import { useGetRemainingDeposit } from "hooks/atomic/useGetRemainingDeposit";
import { useGetTotalOutstanding } from "hooks/atomic/useGetTotalOutstanding";
import { useDebounce } from "hooks/common/useDebounce";
import { useLendingTokenMutations } from "hooks/mutation";
import { isEqualLowerString, isZksyncNetwork } from "utils/network";
import { calculateSafetyBuffer, convertNumberHex } from "utils/utils";

import { estimateBuy } from "utils/dex";
import { MAX_DISCREPANCY } from "constants/contract";
import { Dex } from "utils/dex/enum/dexType";
import { getTokenInfo, isWrapNative } from "utils/token";
import { TokenType } from "utils/dex/enum/tokenType";
import { TokenType as TokenBorrowType } from "types/token";
import icon from "assets/svg/info-icon.svg";
import { useStyles } from "./useStyles";
import PriceDiffTextField from "./PriceDiffTextField";

const RepayModal = ({ data: dataModal, onClose }) => {
  const {
    name,
    lendingToken: {
      address: lendingTokenAddress,
      decimal: lendingDecimal,
      price: lendingTokenPrice,
      underlyingTokens: lendingUnderlyingTokens,
    },
    healthFactor,
    address,
    0: prjToken,
    data,
  } = dataModal;

  const { enqueueSnackbar } = useSnackbar();
  const { chainId, provider } = useWallet();
  const isZkSync = isZksyncNetwork(chainId);

  const context = useBorrowContext();
  const classes = useStyles();
  const [inputNum, setInputValue] = useState("");
  const [isPrepare, setIsPrepare] = useState(false);

  const inputValue = useDebounce(inputNum, 1000);
  const [safetyBuffer, setSafetyBuffer] = useState(1 - 1 / healthFactor);
  const [amountSwap, setAmountSwap] = useState(0);
  const buyCallDataRef = useRef("");
  const estimateAmountInRef = useRef(ethers.BigNumber.from(0));
  const [acceptableCollateral, setAcceptableCollateral] = useState(ethers.BigNumber.from(0));
  const [priceDiff, setPriceDiff] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isNoRoutes, setIsNoRoutes] = useState(false);
  const [allowance, userLendingTokenInfo] = useMemo(
    () => [
      _get(context, ["allowanceForFToken", lendingTokenAddress, "allowance"], false),
      _get(context, ["userTokenInfo"], []).find((token) =>
        isEqualLowerString(token.address, lendingTokenAddress)
      ),
    ],
    [context, lendingTokenAddress]
  );
  const userLendingBalance = userLendingTokenInfo ? userLendingTokenInfo.balanceOf : 0;
  const userLendingBalanceBN = parseUnits(userLendingBalance, lendingDecimal);

  const isFetching = useMemo(() => _get(context, ["isFetching"], false), [context]);
  const refetch = useMemo(() => _get(context, ["refetch"], () => {}), [context]);
  const fTokenRate = useMemo(
    () => _get(context, ["fTokenRate", lendingTokenAddress], {}),
    [context, lendingTokenAddress]
  );

  const { isLoading, repay, approve } = useLendingTokenMutations({
    name,
    amount: inputValue,
    kind: EVENT_TYPES.repay,
  });

  const { repayCollateral, isWaitingTx } = useAtomicRepayment();

  const remainingDepositValue = useGetRemainingDeposit(address);

  const { data: outstanding, loading: outstandingLoading } = useGetTotalOutstanding(
    address,
    lendingTokenAddress
  );
  const outstandingFormat = formatUnits(outstanding || 0, lendingDecimal);

  const owedAmount = outstandingFormat;

  const maxRepayValue = useMemo(
    () => minBigNumber([outstanding, userLendingBalanceBN]),
    [outstanding, userLendingBalanceBN]
  );

  const maxRepayCollateralValue = useMemo(() => minBigNumber([outstanding]), [outstanding]);

  const maxRepayFormatedValue = useMemo(
    () => formatUnits(maxRepayValue || 0, lendingDecimal),
    [maxRepayValue, lendingDecimal]
  );

  const maxRepayCollateralFormatedValue = useMemo(
    () => formatUnits(maxRepayCollateralValue || 0, lendingDecimal),
    [maxRepayCollateralValue, lendingDecimal]
  );

  const isRepayFully = useMemo(
    () => new BigNumber(inputValue).minus(owedAmount).abs().lte(REPAY_TOLERANCE_AMOUNT),
    [inputValue, owedAmount]
  );

  const collateralInfo = useMemo(
    () => getTokenInfo(address, prjToken?.underlyingTokens, isZkSync),
    [address, prjToken, isZkSync]
  );

  const lendingInfo = useMemo(
    () => getTokenInfo(lendingTokenAddress, lendingUnderlyingTokens, isZkSync),
    [lendingUnderlyingTokens, lendingTokenAddress, isZkSync]
  );

  const isRepayDisabled =
    !numberToString(inputValue).length ||
    !Number(inputValue) ||
    parseUnits(inputValue, lendingDecimal).gt(maxRepayValue);

  const isRepayCollateralDisabled =
    isLoadingData ||
    !numberToString(inputValue).length ||
    !Number(inputValue) ||
    Number(amountSwap) === 0 ||
    parseUnits(inputValue, lendingDecimal).gt(maxRepayCollateralValue);

  const needAddAllowance = inputValue
    ? allowance.lt(ethers.utils.parseUnits(numberToString(inputValue || 0), lendingDecimal)) &&
      !isWrapNative(lendingTokenAddress, chainId)
    : false;

  const isLoadingModal = useMemo(
    () => isLoading || isWaitingTx || isFetching || isPrepare || outstandingLoading,
    [isLoading, isWaitingTx, isFetching, isPrepare, outstandingLoading]
  );

  const resetInputValue = () => {
    setInputValue("");
    setIsPrepare(false);
  };

  const handleRepay = async () => {
    try {
      if (needAddAllowance) {
        await approve({ address: lendingTokenAddress, ftoken: _get(fTokenRate, "ftoken") });
        return;
      }
      const repayParams = {
        borrowToken: _get(dataModal, ["lendingToken", "address"], ""),
        prjAddress: address,
        prjAmount: isRepayFully
          ? constants.MaxUint256.toString()
          : parseUnits(convertNumberHex(inputValue), lendingDecimal),
        prjAmountPayable: isRepayFully
          ? ethers.BigNumber.from(outstanding).mul(1001).div(1000)
          : parseUnits(convertNumberHex(inputValue), lendingDecimal),
      };

      await repay(repayParams);
      await refetch();

      resetInputValue();
      onClose();
    } catch {
      resetInputValue();
    }
  };

  const handleRepayCollateral = async () => {
    try {
      if (Number(amountSwap) === 0) {
        enqueueSnackbar(`No pair token!`, { variant: "error", autoHideDuration: 5000 });
        return;
      }
      if (collateralInfo.tokenType === TokenType.LP && lendingInfo.tokenType === TokenType.LP) {
        enqueueSnackbar(`No support for this position`, {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }
      if (!+remainingDepositValue) {
        enqueueSnackbar(`No collateral amount!`, {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }
      if (!numberToString(inputValue).length || !Number(inputValue)) {
        enqueueSnackbar(`No input value!`, {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }

      await repayCollateral({
        collateralInfo,
        lendingInfo,
        collateralAmount: acceptableCollateral,
        buyCallData: buyCallDataRef.current,
        isRepayFully,
      });
      resetInputValue();
      onClose();
    } catch (error) {
      if (
        error.reason !== "execution reverted: UniswapV2Router: INSUFFICIENT_INPUT_AMOUNT" &&
        error.reason !== "execution reverted: AtomicRepayment: invalid amount" &&
        error.reason !== "execution reverted: UniswapV2: INSUFFICIENT_INPUT_AMOUNT" &&
        error.reason !== "user rejected transaction"
      ) {
        enqueueSnackbar("Repay using collateral failed!", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    } finally {
      // resetInputValue();
    }
  };

  const handleChangePriceDiff = (value) => {
    const lendingTokenInUSD = inputValue * lendingTokenPrice;
    const minPrjTokenInUSD =
      Number(formatUnits(estimateAmountInRef.current, prjToken.decimal)) * prjToken.price;
    const minValue = ((minPrjTokenInUSD - lendingTokenInUSD) / lendingTokenInUSD) * 100;
    if (value < minValue) {
      return {
        success: false,
        message: `Minimum value is ${Number(minValue.toFixed(2))}`,
      };
    }
    setPriceDiff(Number(value) / 100);
    return {
      success: true,
      message: "",
    };
  };

  const [logoLendingToken, lendingTokenName, lendingAsset] = useMemo(
    () => [
      _get(dataModal, ["lendingToken", "logo"], "./assets/coins_list/usd-coin.svg"),
      _get(dataModal, ["lendingToken", "name"], ""),
      _get(dataModal, ["lendingToken", "symbol"], ""),
    ],
    [dataModal]
  );

  const handleRepayMax = () => {
    setInputValue(maxRepayFormatedValue);
  };

  const handleRepayCollateralMax = async () => {
    setInputValue(maxRepayCollateralFormatedValue);
  };

  const getDefaultRepayBuffer = (fullyRepay) => {
    const additionBuffer = fullyRepay ? 0.005 : 0;
    return Number(MAX_DISCREPANCY) + additionBuffer;
  };

  useEffect(() => {
    const getEstimateAmountIn = async () => {
      setIsLoadingData(true);
      try {
        const { address: atomicRepaymentAddress } =
          getPrimaryIndexTokenAtomicRepaymentZksync(chainId);
        const repayAmount = new BigNumber(inputValue).toFixed(lendingDecimal);
        if (
          Number(repayAmount) <= 0 ||
          parseUnits(inputValue, lendingDecimal).gt(maxRepayCollateralValue)
        ) {
          throw new Error("Invalid input value");
        }
        const repayAmountBN = parseUnits(repayAmount, lendingDecimal);
        const dexType = isZkSync ? Dex.OpenOcean : Dex.Paraswap;
        const additionBuffer = isRepayFully ? 0.005 : 0;
        const { estimateAmountIn, buyCallData } = await estimateBuy(
          collateralInfo,
          lendingInfo,
          repayAmountBN.mul(101).div(100),
          atomicRepaymentAddress,
          Number(priceDiff) > 0 ? numberToString(priceDiff - additionBuffer) : MAX_DISCREPANCY,
          Number(chainId),
          dexType,
          provider
        );

        const collateralAmountSwap =
          Number(priceDiff) === 0
            ? estimateAmountIn
                .mul(Math.round((1 + getDefaultRepayBuffer(isRepayFully)) * 10000))
                .div(10000)
            : estimateAmountIn
                .mul(Math.round((1 + Number(priceDiff) + additionBuffer) * 10000))
                .div(10000);
        buyCallDataRef.current = buyCallData;
        estimateAmountInRef.current = estimateAmountIn;

        const amountUseToSwap = Number(formatUnits(estimateAmountIn, prjToken.decimal));
        setAmountSwap(amountUseToSwap.toFixed(6));
        setAcceptableCollateral(collateralAmountSwap);
      } catch (e) {
        console.log(e);
        setIsNoRoutes(true);
        setAmountSwap(0);
      }
      setIsLoadingData(false);
    };
    if (!prjToken?.price || !lendingTokenPrice || !inputValue || Number(inputValue) === 0) {
      return;
    }
    getEstimateAmountIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collateralInfo.address, lendingInfo.address, address, inputValue, chainId, priceDiff]);

  useDebugValue(inputValue);

  useEffect(() => {
    if (inputValue) {
      const lvrDenominator = Number(data.pitCollateral.decimal);
      const lvrNumerator =
        (Number(data.totalOutstanding.decimal) - Number(inputValue)) * Number(lendingTokenPrice);

      const sb = 1 - lvrNumerator / lvrDenominator;

      if (sb >= 1) {
        setSafetyBuffer(1);
      } else if (sb <= 0) {
        setSafetyBuffer(0);
      } else {
        setSafetyBuffer(sb);
      }
    } else {
      const newSafetyBuffer = calculateSafetyBuffer(healthFactor);
      setSafetyBuffer(newSafetyBuffer);
    }
  }, [data, healthFactor, inputValue, lendingTokenPrice]);
  return (
    <>
      <DialogLogo
        logoUrl={logoLendingToken}
        name={lendingTokenName}
        underlyingTokens={lendingUnderlyingTokens}
      />
      {isLoadingModal && <Spinner position="absolute" color="success" />}
      <Box pt={5} p={0} className={classes.rootContainer}>
        <NumericTextField
          value={inputNum}
          onChange={setInputValue}
          decimalScale={DECIMAL_SCALE}
          addressToken={lendingTokenAddress}
          decimalToken={lendingDecimal}
          tokenType={TokenBorrowType.LENDING}
        />
        <Box px={2} pb={2} mt={2} pt={2} className={classes.contentInner}>
          <HealthFactorProgressBar value={safetyBuffer} isSafetyBuffer />
          <Box className={classes.maxRepay}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item md={6}>
                Owed
              </Grid>
              <Grid item>
                <Typography color="primary">{`${owedAmount} ${lendingAsset}`} </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={9}>
              <DialogApplyButton disabled={isRepayDisabled} onClick={handleRepay}>
                {needAddAllowance ? "Enable" : "Repay"}
              </DialogApplyButton>
            </Grid>
            <Grid item xs={3}>
              <Button className={classes.maxButton} onClick={handleRepayMax}>
                MAX
              </Button>
            </Grid>
          </Grid>

          <Box marginTop={3}>
            <Box className={classes.collateralAmount}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid container md={6}>
                  <Box display="inline">Collateral amount</Box>
                  <Tooltip
                    title={
                      <Box>
                        <p>The collateral amount is used for repayment.</p>
                      </Box>
                    }
                    arrow
                    placement="top"
                  >
                    <img src={icon} style={{ width: 20, marginRight: 1 }} alt="" />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography color="primary">
                    {`${amountSwap} ${prjToken.symbol} `}
                    <span style={{ color: "#949494" }}>
                      (<NumericText value={amountSwap * prjToken.price} precision={2} moneyValue />)
                    </span>
                  </Typography>
                </Grid>
              </Grid>{" "}
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid container md={6}>
                  <Box display="inline">Max price discrepancy</Box>
                  <Tooltip
                    title="The maximum allowed difference between the expected and actual exchange rates. If exceeded, the transaction is reverted."
                    arrow
                    placement="top"
                  >
                    <img src={icon} style={{ width: 20, marginRight: 1 }} alt="" />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <PriceDiffTextField
                    priceDiff={priceDiff}
                    amountSwap={parseUnits(amountSwap, prjToken.decimal)}
                    maxAmountSwap={acceptableCollateral}
                    decimals={prjToken.decimal}
                    symbol={prjToken.symbol}
                    onChange={handleChangePriceDiff}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid item xs={9}>
                <DialogApplyButton
                  disabled={isRepayCollateralDisabled}
                  onClick={handleRepayCollateral}
                >
                  Repay using collateral
                </DialogApplyButton>
              </Grid>
              <Grid item xs={3}>
                <Button className={classes.maxButton} onClick={handleRepayCollateralMax}>
                  {isLoadingData ? (
                    <CircularProgress
                      className={classes.circularProgress}
                      size={16}
                      color="inherit"
                    />
                  ) : (
                    "MAX"
                  )}
                </Button>
              </Grid>
              {isNoRoutes && (
                <Typography variant="body2" color="error">
                  No routes found with enough liquidity
                </Typography>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RepayModal;
