import BigNumber from "bignumber.js";
import { USD_DECIMAL } from "constants/contract";
import { constants } from "ethers";
import _ from "lodash";
import { formatUnits } from "utils/number";
import { FTokenMethod, PITContractMethod } from "constants/methodName";
import { ContractName } from "constants/contractName";
import { getLimitBorrowingOfLendingTokenFromMultiData } from "./getLimitBorrowingOfLendingTokenFromMultiData";
import { handleEvaluation } from "./handleEvaluation";
import { handleGetDataFromPosition } from "./handleGetDataFromPosition";
import { handleGetHealthFactor } from "./handleGetHealthFactor";
import { handleGetIssuedPITAmount } from "./handleGetIssuedPITAmount";
import { handleGetLiquidationThreshold } from "./handleGetLiquidationThreshold";
import { handleGetPITRemainingAmount } from "./handleGetPITRemainingAmount";
import { handleLvrLending } from "./handleLvrLending";
import { handleApy } from "./handleApy";
import { handleGetAllowanceForFToken } from "./handleGetAllowanceForFToken";
import { handleGetCash } from "./handleGetCash";

export function handleGetDataForBorrowContextZkSync(resultMultiCall, isMainNet, decimalTokens) {
  const pitContract = _.get(resultMultiCall, ContractName.PITContract);
  const ERC20TokenContract = _.get(resultMultiCall, ContractName.ERC20Token);
  const UniswapV2FactoryContract = _.get(resultMultiCall, ContractName.UniswapV2FactoryContract);
  const JumpRateModelContract = _.get(resultMultiCall, ContractName.JumpRateModelContract);
  const FTokenContract = _.omit(resultMultiCall, [
    ContractName.PITContract,
    ContractName.ERC20Token,
    ContractName.UniswapV2FactoryContract,
    ContractName.JumpRateModelContract,
    ContractName.PriceContract,
  ]);

  const pairToken = isMainNet
    ? {}
    : UniswapV2FactoryContract.reduce((prev, cur) => {
        const lendingToken = _.get(cur, ["methodParameters", 0]);
        const pairValue = _.get(cur, ["returnValues", 0]);

        return { ...prev, [lendingToken]: pairValue };
      }, {});

  const dataOnPitContract = _.groupBy(pitContract, "methodName");
  const dataOnFTokenContract = FTokenContract;
  const dataOnERC20TokenContract = _.groupBy(ERC20TokenContract, "methodName");

  const allowanceForFTokenList = _.get(dataOnERC20TokenContract, FTokenMethod.allowance, {});
  const allowanceForFToken = handleGetAllowanceForFToken(allowanceForFTokenList);

  const cashFToken = handleGetCash(dataOnFTokenContract, decimalTokens);

  const FTokenData = {
    allowanceForFToken,
    cashFToken,
  };

  const blocksPerYear = _.get(JumpRateModelContract, [0, "returnValues", 0], 0);

  const fTokenApyList = handleApy(dataOnFTokenContract, blocksPerYear);

  const liquidationThresholdList = handleGetLiquidationThreshold(
    _.get(dataOnPitContract, PITContractMethod.liquidationThreshold, {})
  );

  const fTokenAddresses = _.get(dataOnPitContract, PITContractMethod.lendingTokenInfo, []).map(
    (o) => ({
      ftoken: _.get(o, ["returnValues", 2]),
      isPaused: _.get(o, ["returnValues", 1]),
      token: _.get(o, ["methodParameters", 0]),
    })
  );

  const lendingTokenInfo = _.get(dataOnPitContract, [PITContractMethod.lendingTokenInfo]);
  const lvrLending = handleLvrLending(lendingTokenInfo);

  const pitRemainingList = _.get(dataOnPitContract, [PITContractMethod.pitRemaining]);
  const pitCollateralList = _.get(dataOnPitContract, [PITContractMethod.pitCollateral]);
  const positionData = _.get(dataOnPitContract, [PITContractMethod.getPosition]);

  const evaluationList = _.get(dataOnPitContract, [PITContractMethod.getTokenEvaluation]);
  const evaluations = handleEvaluation(evaluationList);

  const depositedAssets = handleGetDataFromPosition(positionData, 0);
  const totalOutstandingAssets = handleGetDataFromPosition(positionData, 1);
  const accrualAssets = handleGetDataFromPosition(positionData, 2);

  const totalPITCollateral = handleGetIssuedPITAmount(
    pitCollateralList,
    pitRemainingList,
    totalOutstandingAssets
  );

  const healthFactor = handleGetHealthFactor(positionData);

  const getLendingAvailableToBorrowList = _.get(dataOnPitContract, [
    PITContractMethod.getLendingAvailableToBorrow,
  ]);

  const pitRemainingX = handleGetPITRemainingAmount(getLendingAvailableToBorrowList);

  const limitBorrowInLendingToken = getLimitBorrowingOfLendingTokenFromMultiData(dataOnPitContract);

  const totalBorrowedInLendingToken = _.get(dataOnPitContract, [
    PITContractMethod.getTotalBorrowPerLendingToken,
  ])?.reduce(
    (res, resultCall) => ({
      ...res,
      [_.get(resultCall, ["reference"], constants.AddressZero)]: formatUnits(
        _.get(resultCall, ["returnValues", 0], "0"),
        USD_DECIMAL
      ),
    }),
    {}
  );

  const currentLimitLendingToken = _.reduce(
    limitBorrowInLendingToken,
    (res, limit, key) => ({
      ...res,
      [key]: {
        limitMax: limit,
        totalBorrowed: _.get(totalBorrowedInLendingToken, [key], "0"),
        limit: new BigNumber(limit)
          .minus(_.get(totalBorrowedInLendingToken, [key], "0"))
          .toString(),
      },
    }),
    {}
  );

  const getLendingToken = _.get(dataOnPitContract, [PITContractMethod.getLendingToken]);
  const borrwedList = _.reduce(
    getLendingToken,
    (prev, cur) => ({
      ...prev,
      [cur.methodParameters[1]]: cur.returnValues[0],
    }),
    {}
  );

  return {
    currentLimitLendingToken,
    currentLimitPrjToken: {},
    pitRemaining: pitRemainingX,
    totalPITCollateral,
    totalOutstandingAssets,
    accrualAssets,
    healthFactor,
    depositedAssets,
    fTokenAddresses,
    fTokenApyList,
    FTokenData,
    lvrLending,
    liquidationThresholdList,
    evaluations,
    borrwedList,
    pairToken,
  };
}
