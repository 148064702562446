/* eslint-disable no-loop-func */
import { ethers } from "ethers";
import { useWallet } from "hooks";
import { useMultiCallContract } from "hooks/contract/multicall/useMultiCallContract";
import { reduce } from "lodash";
import { useQuery } from "react-query";
import { generateMulticallRequest } from "utils/contract/multicall";
import { MainNetworkSupported } from "utils/network";
import { usePITContractRead } from "hooks/contract/usePITContract";
import { usePriceContract } from "hooks/contract/core/usePriceContract";
import { useUniswapV2FactoryContract } from "hooks/contract/core/useUniswapV2FactoryContract";
import { useDataToUpdatePrice } from "hooks/pyth/useDataToUpdatePrice";
import { useMemo } from "react";
import { ContractName } from "constants/contractName";
import { PriceContractMethod } from "constants/methodName";
import { useJumpRateModelContract } from "hooks/contract/core/useJumpRateModelContract";
import { useDecimalToken } from "../useDecimalToken";
import { handleGetDataForBorrowContextZkSync } from "./handleGetDataForBorrowContextZkSync";
import { prepareRequestMulticallZksync } from "./prepareRequestMulticallZksync";
import { useGetTokens } from "../useTokenSupported";

const usePrimaryIndexTokenMultiCall = () => {
  const { chainId, account } = useWallet();
  const isMainNet = MainNetworkSupported.includes(+chainId);

  const { availableBorrowTokens, projectTokenList } = useGetTokens();
  const { data: decimalTokens } = useDecimalToken([...availableBorrowTokens, ...projectTokenList]);

  const { callRequest } = useMultiCallContract();
  const {
    data: { PitContract },
  } = usePITContractRead();
  const { PriceContract } = usePriceContract();
  const { JumpRateModelContract } = useJumpRateModelContract();
  const { uniswapV2Factory: UniswapV2FactoryContract } = useUniswapV2FactoryContract();

  const tokenAddressList = useMemo(
    () =>
      Array.from(new Set([...projectTokenList, ...availableBorrowTokens].map((o) => o.address))),
    [availableBorrowTokens, projectTokenList]
  );
  const { refetch } = useDataToUpdatePrice(tokenAddressList);

  return useQuery(
    ["borrowed-pit-multicall", account, chainId, tokenAddressList.sort().toString()],
    async () => {
      const { data: pythData } = await refetch();
      const listToken = [...projectTokenList];

      const listPrepareRequestMultical = prepareRequestMulticallZksync({
        PITContract: PitContract,
        JumpRateModelContract,
        listToken,
        availableBorrowTokens,
        account,
        decimalTokens,
        chainId,
        UniswapV2FactoryContract,
        pythData,
      });

      const chunkSize = 2500;
      const promises = [];

      // loop chunk with group
      let buffer = [];
      for (let i = 0; i < listPrepareRequestMultical.length; i += 1) {
        buffer.push(listPrepareRequestMultical[i]);
        if (buffer.length >= chunkSize || i >= listPrepareRequestMultical.length - 1) {
          const requests = [
            generateMulticallRequest(
              PriceContract,
              PriceContractMethod.updatePricesMethod,
              [pythData.priceIds, pythData.updateData],
              PriceContractMethod.updatePricesMethod,
              ContractName.PriceContract,
              pythData.payableAmount
            ),
            ...buffer,
          ];
          buffer = [];
          promises.push(callRequest(requests));
        }
      }

      const returnData = await Promise.all(promises);
      const results = {};
      returnData.forEach((data) => {
        Object.keys(data).forEach((key) => {
          if (!results[key]) {
            results[key] = [];
          }
          results[key].push(...data[key]);
        });
      });
      const {
        currentLimitLendingToken,
        currentLimitPrjToken,
        pitRemaining,
        totalPITCollateral,
        totalOutstandingAssets,
        accrualAssets,
        healthFactor,
        depositedAssets,
        fTokenAddresses,
        fTokenApyList,
        FTokenData,
        liquidationThresholdList,
        lvrLending,
        evaluations,
        borrwedList,
        pairToken,
      } = handleGetDataForBorrowContextZkSync(results, isMainNet, decimalTokens);

      const newTotalOutStanding = reduce(
        totalOutstandingAssets,
        (res, value, key) => {
          const newData = {
            ...value,
            loanBody: ethers.BigNumber.from(value?.value || "0").toString(),
            value: ethers.BigNumber.from(value?.value || "0")
              .add(accrualAssets[key]?.value || 0)
              .toString(),
          };
          return {
            ...res,
            [key]: newData,
          };
        },
        {}
      );

      return {
        availableBorrowTokens,
        currentLimitLendingToken,
        currentLimitPrjToken,
        fTokenAddresses,
        fTokenApyList,
        FTokenData,
        projectTokenList,
        pitCollateralBalance: totalPITCollateral,
        pitRemaining,
        totalOutstandingAssets: newTotalOutStanding,
        accrualAssets,
        healthFactor,
        depositedAssets,
        liquidationThresholdList,
        lvrLending,
        evaluations,
        borrwedList,
        pairToken,
      };
    },
    {
      retry: 3,
      enabled:
        !!PitContract &&
        !!PriceContract &&
        !!decimalTokens &&
        !!availableBorrowTokens?.length &&
        !!projectTokenList?.length &&
        !!account,
    }
  );
};

export default usePrimaryIndexTokenMultiCall;
