const { CHAINID_NETWORK } = require("./NetworkChainId");

export const GraphByChainId = {
  [CHAINID_NETWORK.ethereum]: process.env.REACT_APP_GRAPH_QL_CLI_ETHEREUM,
  [CHAINID_NETWORK.polygon]: process.env.REACT_APP_GRAPH_QL_CLI_POLYGON,
  [CHAINID_NETWORK.optimism]: process.env.REACT_APP_GRAPH_QL_CLI_OPTIMISM,
  [CHAINID_NETWORK.arbitrum]: process.env.REACT_APP_GRAPH_QL_CLI_ARBITRUM,
  [CHAINID_NETWORK.zkSync]: process.env.REACT_APP_GRAPH_QL_CLI_ZKSYNC,
  [CHAINID_NETWORK.base]: process.env.REACT_APP_GRAPH_QL_CLI_BASE,
};
