import { CircularProgress } from "@material-ui/core";
import { useLeverageContext } from "context/InstantsLeverage/LeverageContext/useLeverageContext";
import { BigNumber, Contract } from "ethers";
import useWallet from "hooks/useWallet";
import { get } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useMutation as useRTKMutation, useQueryClient as useRTKQueryClient } from "react-query";

import { getInstantLeverageZkSyncContract } from "utils/ethereum/contracts";
import { useAmplifyContext } from "context/InstantsLeverage/AmplifyContext/useAmplifyContext";
import { useMarginTradeContext } from "context/InstantsLeverage/MarginTradeContext/useMarginTradeContext";
import { parseUnits } from "utils/number";
import { usePriceContract } from "hooks/contract/core/usePriceContract";
import {
  getDataToUpdatePrice,
  getTokensNeedToUpdatePrice,
} from "utils/ethereum/getDataToUpdatePrice";
import { isZksyncNetwork, ZERO_ADDRESS } from "utils/network";
import { estimateSell } from "utils/dex";
import { Dex } from "utils/dex/enum/dexType";
import { getTokenInfo, getTokenTuple, isWrapNative } from "utils/token";
import { MAX_DISCREPANCY, USD_DECIMAL } from "constants/contract";
import { getGasLimit } from "utils/utils";
import { usePITWrappedTokenGatewayContract } from "./usePITWrappedTokenGatewayContract";

const usePITLeverageContract = (chainId, signer) =>
  useMemo(() => {
    const contractInfo = getInstantLeverageZkSyncContract(chainId);
    return new Contract(contractInfo.address, contractInfo.abi, signer);
  }, [chainId, signer]);

const calculateLendingTokenCount = async (
  lendingToken,
  notional,
  priceContract,
  leverageContract
) => {
  const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
    lendingToken,
    ZERO_ADDRESS,
    true,
    priceContract
  );
  const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
    tokenAddressNeedToUpdatePrice,
    priceContract
  );

  const res = await leverageContract.callStatic.calculateLendingTokenCountWithUpdatePrices(
    lendingToken,
    notional,
    priceIds,
    updateData,
    { value: payableAmount }
  );

  return res;
};

export const useLeverageContract = () => {
  const queryRTKClient = useRTKQueryClient();
  const { account, chainId, signer } = useWallet();
  const { PriceContract } = usePriceContract();

  const leverageContract = usePITLeverageContract(chainId, signer);
  const {
    callback: { leveragedBorrowWithProjectETHCall },
  } = usePITWrappedTokenGatewayContract();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    refetchLaveragedBorrowList,
    shortAssetSelected,
    notionalExp: NEXP,
  } = useLeverageContext();

  const { resetStates: resetAmplifyContextStates } = useAmplifyContext();
  const { resetStates: resetMarginTradeContextStates } = useMarginTradeContext();

  const [shortAmountt, setShortAmount] = useState();

  useEffect(() => {
    const getShortAmount = async () => {
      if (!shortAssetSelected || !NEXP || NEXP === "NaN" || !leverageContract) return;
      const notionalExpBN = parseUnits(NEXP, USD_DECIMAL).toString();
      const shortAmount = await calculateLendingTokenCount(
        shortAssetSelected.address,
        notionalExpBN,
        PriceContract,
        leverageContract
      );

      setShortAmount(shortAmount);
    };
    getShortAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortAssetSelected?.address, NEXP, leverageContract?.address, PriceContract?.address]);

  const { mutateAsync: openLeveragePosition, isLoading } = useRTKMutation(
    async (variable) => {
      const { longAsset, shortAsset, notionalExp, marginLong, leverageType } = variable;

      const { address: shortAddress, underlyingTokens: shortUnderlyingTokens } = shortAsset;
      const { address: longAddress, underlyingTokens: longUnderlyingTokens } = longAsset;

      const shortAmount = await calculateLendingTokenCount(
        shortAddress,
        notionalExp,
        PriceContract,
        leverageContract
      );

      const longInfo = getTokenInfo(longAddress, longUnderlyingTokens, isZksyncNetwork(chainId));
      const shortInfo = getTokenInfo(shortAddress, shortUnderlyingTokens, isZksyncNetwork(chainId));

      const tokenAddressNeedToUpdatePrice = await getTokensNeedToUpdatePrice(
        longAddress,
        shortAddress,
        true,
        PriceContract
      );
      const dexType = isZksyncNetwork(chainId) ? Dex.OpenOcean : Dex.Paraswap;
      const sellCallData = await estimateSell(
        shortInfo,
        longInfo,
        shortAmount.mul(100).div(105),
        MAX_DISCREPANCY,
        leverageContract.address,
        Number(chainId),
        dexType,
        signer.provider
      );

      const { priceIds, payableAmount, updateData } = await getDataToUpdatePrice(
        tokenAddressNeedToUpdatePrice,
        PriceContract
      );
      if (isWrapNative(longAddress, chainId)) {
        const addingAmount = await leverageContract.calculateAddingAmount(
          account,
          longAddress,
          marginLong
        );
        await leveragedBorrowWithProjectETHCall({
          lendingInfo: getTokenTuple(shortInfo),
          notionalExposure: notionalExp,
          marginCollateralAmount: marginLong,
          buyCalldata: sellCallData.buyCallData,
          leverageType,
          priceIds,
          updateData,
          updateFee: payableAmount,
          payableAmount: BigNumber.from(addingAmount).add(payableAmount),
        });
      } else {
        const gasLimit = await getGasLimit(
          leverageContract,
          "leveragedBorrow",
          getTokenTuple(longInfo),
          getTokenTuple(shortInfo),
          notionalExp,
          marginLong,
          sellCallData.buyCallData,
          leverageType,
          priceIds,
          updateData,
          { value: payableAmount }
        );
        const tx = await leverageContract.leveragedBorrow(
          getTokenTuple(longInfo),
          getTokenTuple(shortInfo),
          notionalExp,
          marginLong,
          sellCallData.buyCallData,
          leverageType,
          priceIds,
          updateData,
          { value: payableAmount, gasLimit }
        );
        await tx.wait(1);
      }
    },
    {
      onSettled: async (_data, err) => {
        closeSnackbar();
        if (err) {
          const message =
            JSON.stringify(err)
              .split('"')
              .find((mes) => mes.includes("reverted")) ||
            get(err, "reason") ||
            get(err, "message");
          enqueueSnackbar(`Error Tx - ${message}!`, { variant: "error", autoHideDuration: 5000 });
        } else {
          await queryRTKClient.invalidateQueries(["available-multicall", account]);
          await queryRTKClient.invalidateQueries(["borrowed-pit-multicall", account]);
          enqueueSnackbar("Success Tx:Leverage Position Opened!", {
            variant: "success",
            autoHideDuration: 5000,
          });

          if (resetAmplifyContextStates) {
            resetAmplifyContextStates();
          }

          if (resetMarginTradeContextStates) {
            resetMarginTradeContextStates();
          }

          await refetchLaveragedBorrowList({
            account,
          });
        }
      },
      onMutate: () => {
        enqueueSnackbar(
          <>
            <CircularProgress size={16} color="inherit" style={{ marginRight: "8px" }} /> Waiting
            Transaction: Open Leverage Position!
          </>,
          {
            persist: true,
            autoHideDuration: 5000,
          }
        );
      },
    }
  );

  return {
    leverageContract,
    openLeveragePosition: isLoading ? () => {} : openLeveragePosition,
    shortAmount: shortAmountt,
  };
};
