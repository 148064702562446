import { BigNumber } from "ethers";
import { get } from "lodash";
import { ZERO_ADDRESS } from "utils/network";
import { formatUnits } from "utils/number";

export function handleGetCash(resultMulticall, decimalTokens) {
  const result = {};
  Object.keys(resultMulticall).forEach((o) => {
    const token = get(resultMulticall[o], [2, "reference"], ZERO_ADDRESS);
    const raw = get(resultMulticall[o], [2, "returnValues", 0], 0);
    const cashValue = BigNumber.from(raw);

    result[token] = {
      cash: formatUnits(cashValue, decimalTokens[token]),
    };
  });
  return result;
}
